.grid {
  margin: 0px auto 20px auto;
  padding: 0px;
  display: grid;
  border: 1px solid #ddd;
}

.grid:hover {
  cursor: cell;
}
