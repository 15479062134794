@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');

.topBar {
  margin: 0px;
  padding: 0px 10px;
  box-sizing: border-box;
  background-color: rgb(38, 70, 83);
  width: 100%;
  /* height: 100%; */
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title {
  font-family: 'Mulish', sans-serif;
  font-size: 30px;
  font-weight: 1000;
  color: rgb(173, 232, 244);
  background-color: transparent;
  margin: 10px auto;
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
}

.topBarItemContainer {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin: 10px auto;
}

.algorithmForm {
  display: inline-flex;
  flex-wrap: nowrap;
}

.topBarButton,
.topBarButtonWhileAnimating {
  border: none;
  border-radius: 5px;
  height: 40px;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 300;
  user-select: none;
  margin-left: 10px;
  margin-right: 10px;
}

.topBarButton {
  background-color: rgb(17, 138, 178);
  color: white;
}

.topBarButton:hover {
  cursor: pointer;
  background-color: rgb(6, 214, 160, 0.9);
  color: rgb(38, 70, 83);
}

.resetButton:hover {
  background-color: rgb(255, 107, 107);
}

.topBarButtonWhileAnimating,
.topBarButtonWhileAnimating:hover {
  background-color: rgb(17, 138, 178);
  color: white;
}

.topBarButton:focus,
.topBarButtonWhileAnimating:focus {
  outline: none;
}
