.square,
.startSquare,
.endSquare,
.wallSquare,
.weightSquare,
.visitedSquare,
.visitedFinishedSquare,
.visitedHeadSquare,
.visitedWeightSquare,
.visitedWeightFinishedSquare,
.pathSquare,
.pathWeightSquare,
.pathHeadSquare,
.pathFinishedSquare,
.pathFinishedWeightSquare {
  width: 100%;
  height: 100%;
  margin: auto;
  user-select: none;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square:focus,
.startSquare:focus,
.endSquare:focus,
.wallSquare:focus,
.weightSquare:focus,
.visitedSquare:focus,
.visitedWeightSquare:focus,
.visitedHeadSquare:focus,
.visitedFinishedSquare:focus,
.visitedFinishedWeightSquare:focus,
.pathSquare:focus,
.pathWeightSquare:focus,
.pathHeadSquare:focus,
.pathFinishedSquare:focus,
.pathFinishedWeightSquare {
  outline: none;
}

.weightImg,
.startImg,
.endImg {
  width: 95%;
  height: 95%;
  user-select: none;
  display: block;
}

.square {
  border: 0.1px solid #ddd;
}

.startSquare {
  background-color: rgb(6, 214, 160, 0.9);
  background-image: url('./../images/start.svg');
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center center;
}

.endSquare {
  background-color: rgb(255, 107, 107);
  background-image: url('./../images/end.svg');
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center center;
}

@keyframes wall {
  0% {
    background-color: rgb(38, 70, 83);
  }
  50% {
    background-color: rgb(38, 70, 83);
    transform: scale(1.2);
  }
  100% {
    background-color: rgb(38, 70, 83);
    border-radius: 0%;
  }
}

.wallSquare {
  animation-name: wall;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  will-change: transform, border-radius, background-color;
}

@keyframes weight {
  0% {
    background-image: url('./../images/weight.png');
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
  }
  50% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
  }
  99% {
    border: none;
  }
  100% {
    background-image: url('./../images/weight.png');
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    border: 0.1px solid #ddd;
  }
}

.weightSquare {
  background-image: url('./../images/weight.png');
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
  border: 0.1px solid #ddd;

  animation-name: weight;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  will-change: transform, border;
}

@keyframes visited {
  0% {
    background-color: rgb(116, 0, 184);
    transform: scale(0.3);
    border: none;
    border-radius: 50%;
  }
  25% {
    background-color: rgb(94, 96, 206);
  }
  50% {
    background-color: rgb(72, 191, 227);
    border-radius: 50%;
  }
  75% {
    transform: scale(1.2);
    background-color: rgb(128, 255, 219);
    border: none;
    border-radius: 0%;
  }
  75.1% {
    border: 1px solid rgb(173, 232, 244);
  }
  100% {
    transform: scale(1);
    background-color: rgb(86, 207, 225);
    border: 1px solid rgb(173, 232, 244);
  }
}

.visitedSquare,
.visitedWeightSquare {
  background-color: rgb(86, 207, 225);
  border: 1px solid rgb(173, 232, 244);

  animation-name: visited;
  animation-duration: 1500ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  will-change: transform, border, border-radius, background-color;
}

@keyframes visitedWeight {
  0% {
    background-image: url('./../images/weight.png');
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgb(116, 0, 184);
    transform: scale(0.3);
    border: none;
    border-radius: 50%;
  }
  25% {
    background-color: rgb(94, 96, 206);
  }
  50% {
    background-color: rgb(72, 191, 227);
    border-radius: 50%;
  }
  75% {
    transform: scale(1.2);
    background-color: rgb(128, 255, 219);
    border: none;
    border-radius: 0%;
  }
  75.1% {
    border: 1px solid rgb(173, 232, 244);
  }
  100% {
    background-image: url('./../images/weight.png');
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(1);
    background-color: rgb(86, 207, 225);
    border: 1px solid rgb(173, 232, 244);
  }
}

.visitedWeightSquare {
  background-image: url('./../images/weight.png');
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
  animation-name: visitedWeight;
}

.visitedFinishedSquare,
.visitedFinishedWeightSquare {
  background-color: rgb(86, 207, 225);
  border: 1px solid rgb(173, 232, 244);
}

.visitedFinishedWeightSquare {
  background-image: url('./../images/weight.png');
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
}

.visitedHeadSquare {
  background-color: rgb(255, 255, 63);
  border: 1px solid #eee;
}

@keyframes path {
  0% {
    transform: scale(0.65);
    background-color: rgb(255, 255, 63);
    border-radius: 30%;
  }
  100% {
    transform: scale(1);
    background-color: rgb(255, 255, 63);
    border-radius: 0%;
  }
}

.pathSquare,
.pathWeightSquare {
  background-color: rgb(255, 255, 63);
  border-radius: 0%;
  animation-name: path;
  animation-duration: 100ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  will-change: transform, border-radius, background-color;
}

@keyframes pathWeight {
  0% {
    background-image: url('./../images/weight.png');
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(0.65);
    background-color: rgb(255, 255, 63);
    border-radius: 30%;
  }
  100% {
    background-image: url('./../images/weight.png');
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(1);
    background-color: rgb(255, 255, 63);
    border-radius: 0%;
  }
}

.pathWeightSquare {
  animation-name: pathWeight;
  background-image: url('./../images/weight.png');
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgb(255, 255, 63);
  border-radius: 0%;
}

.pathHeadSquare {
  background-color: rgb(255, 255, 63);
  background-image: url('./../images/start.svg');
}

.pathFinishedSquare,
.pathFinishedWeightSquare {
  background-color: rgb(255, 255, 63);
}

.pathFinishedWeightSquare {
  background-image: url('./../images/weight.png');
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
}
