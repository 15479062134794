@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap);
.square,
.startSquare,
.endSquare,
.wallSquare,
.weightSquare,
.visitedSquare,
.visitedFinishedSquare,
.visitedHeadSquare,
.visitedWeightSquare,
.visitedWeightFinishedSquare,
.pathSquare,
.pathWeightSquare,
.pathHeadSquare,
.pathFinishedSquare,
.pathFinishedWeightSquare {
  width: 100%;
  height: 100%;
  margin: auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square:focus,
.startSquare:focus,
.endSquare:focus,
.wallSquare:focus,
.weightSquare:focus,
.visitedSquare:focus,
.visitedWeightSquare:focus,
.visitedHeadSquare:focus,
.visitedFinishedSquare:focus,
.visitedFinishedWeightSquare:focus,
.pathSquare:focus,
.pathWeightSquare:focus,
.pathHeadSquare:focus,
.pathFinishedSquare:focus,
.pathFinishedWeightSquare {
  outline: none;
}

.weightImg,
.startImg,
.endImg {
  width: 95%;
  height: 95%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: block;
}

.square {
  border: 0.1px solid #ddd;
}

.startSquare {
  background-color: rgb(6, 214, 160, 0.9);
  background-image: url(/static/media/start.ff2b9671.svg);
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center center;
}

.endSquare {
  background-color: rgb(255, 107, 107);
  background-image: url(/static/media/end.93326d55.svg);
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center center;
}

@-webkit-keyframes wall {
  0% {
    background-color: rgb(38, 70, 83);
  }
  50% {
    background-color: rgb(38, 70, 83);
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    background-color: rgb(38, 70, 83);
    border-radius: 0%;
  }
}

@keyframes wall {
  0% {
    background-color: rgb(38, 70, 83);
  }
  50% {
    background-color: rgb(38, 70, 83);
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    background-color: rgb(38, 70, 83);
    border-radius: 0%;
  }
}

.wallSquare {
  -webkit-animation-name: wall;
          animation-name: wall;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  will-change: transform, border-radius, background-color;
}

@-webkit-keyframes weight {
  0% {
    background-image: url(/static/media/weight.17d008f4.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
  }
  50% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
  }
  99% {
    border: none;
  }
  100% {
    background-image: url(/static/media/weight.17d008f4.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    border: 0.1px solid #ddd;
  }
}

@keyframes weight {
  0% {
    background-image: url(/static/media/weight.17d008f4.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
  }
  50% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
  }
  99% {
    border: none;
  }
  100% {
    background-image: url(/static/media/weight.17d008f4.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    border: 0.1px solid #ddd;
  }
}

.weightSquare {
  background-image: url(/static/media/weight.17d008f4.png);
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
  border: 0.1px solid #ddd;

  -webkit-animation-name: weight;

          animation-name: weight;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  will-change: transform, border;
}

@-webkit-keyframes visited {
  0% {
    background-color: rgb(116, 0, 184);
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    border: none;
    border-radius: 50%;
  }
  25% {
    background-color: rgb(94, 96, 206);
  }
  50% {
    background-color: rgb(72, 191, 227);
    border-radius: 50%;
  }
  75% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    background-color: rgb(128, 255, 219);
    border: none;
    border-radius: 0%;
  }
  75.1% {
    border: 1px solid rgb(173, 232, 244);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: rgb(86, 207, 225);
    border: 1px solid rgb(173, 232, 244);
  }
}

@keyframes visited {
  0% {
    background-color: rgb(116, 0, 184);
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    border: none;
    border-radius: 50%;
  }
  25% {
    background-color: rgb(94, 96, 206);
  }
  50% {
    background-color: rgb(72, 191, 227);
    border-radius: 50%;
  }
  75% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    background-color: rgb(128, 255, 219);
    border: none;
    border-radius: 0%;
  }
  75.1% {
    border: 1px solid rgb(173, 232, 244);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: rgb(86, 207, 225);
    border: 1px solid rgb(173, 232, 244);
  }
}

.visitedSquare,
.visitedWeightSquare {
  background-color: rgb(86, 207, 225);
  border: 1px solid rgb(173, 232, 244);

  -webkit-animation-name: visited;

          animation-name: visited;
  -webkit-animation-duration: 1500ms;
          animation-duration: 1500ms;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  will-change: transform, border, border-radius, background-color;
}

@-webkit-keyframes visitedWeight {
  0% {
    background-image: url(/static/media/weight.17d008f4.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgb(116, 0, 184);
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    border: none;
    border-radius: 50%;
  }
  25% {
    background-color: rgb(94, 96, 206);
  }
  50% {
    background-color: rgb(72, 191, 227);
    border-radius: 50%;
  }
  75% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    background-color: rgb(128, 255, 219);
    border: none;
    border-radius: 0%;
  }
  75.1% {
    border: 1px solid rgb(173, 232, 244);
  }
  100% {
    background-image: url(/static/media/weight.17d008f4.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: rgb(86, 207, 225);
    border: 1px solid rgb(173, 232, 244);
  }
}

@keyframes visitedWeight {
  0% {
    background-image: url(/static/media/weight.17d008f4.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgb(116, 0, 184);
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    border: none;
    border-radius: 50%;
  }
  25% {
    background-color: rgb(94, 96, 206);
  }
  50% {
    background-color: rgb(72, 191, 227);
    border-radius: 50%;
  }
  75% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    background-color: rgb(128, 255, 219);
    border: none;
    border-radius: 0%;
  }
  75.1% {
    border: 1px solid rgb(173, 232, 244);
  }
  100% {
    background-image: url(/static/media/weight.17d008f4.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: rgb(86, 207, 225);
    border: 1px solid rgb(173, 232, 244);
  }
}

.visitedWeightSquare {
  background-image: url(/static/media/weight.17d008f4.png);
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-animation-name: visitedWeight;
          animation-name: visitedWeight;
}

.visitedFinishedSquare,
.visitedFinishedWeightSquare {
  background-color: rgb(86, 207, 225);
  border: 1px solid rgb(173, 232, 244);
}

.visitedFinishedWeightSquare {
  background-image: url(/static/media/weight.17d008f4.png);
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
}

.visitedHeadSquare {
  background-color: rgb(255, 255, 63);
  border: 1px solid #eee;
}

@-webkit-keyframes path {
  0% {
    -webkit-transform: scale(0.65);
            transform: scale(0.65);
    background-color: rgb(255, 255, 63);
    border-radius: 30%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: rgb(255, 255, 63);
    border-radius: 0%;
  }
}

@keyframes path {
  0% {
    -webkit-transform: scale(0.65);
            transform: scale(0.65);
    background-color: rgb(255, 255, 63);
    border-radius: 30%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: rgb(255, 255, 63);
    border-radius: 0%;
  }
}

.pathSquare,
.pathWeightSquare {
  background-color: rgb(255, 255, 63);
  border-radius: 0%;
  -webkit-animation-name: path;
          animation-name: path;
  -webkit-animation-duration: 100ms;
          animation-duration: 100ms;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  will-change: transform, border-radius, background-color;
}

@-webkit-keyframes pathWeight {
  0% {
    background-image: url(/static/media/weight.17d008f4.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transform: scale(0.65);
            transform: scale(0.65);
    background-color: rgb(255, 255, 63);
    border-radius: 30%;
  }
  100% {
    background-image: url(/static/media/weight.17d008f4.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: rgb(255, 255, 63);
    border-radius: 0%;
  }
}

@keyframes pathWeight {
  0% {
    background-image: url(/static/media/weight.17d008f4.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transform: scale(0.65);
            transform: scale(0.65);
    background-color: rgb(255, 255, 63);
    border-radius: 30%;
  }
  100% {
    background-image: url(/static/media/weight.17d008f4.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: rgb(255, 255, 63);
    border-radius: 0%;
  }
}

.pathWeightSquare {
  -webkit-animation-name: pathWeight;
          animation-name: pathWeight;
  background-image: url(/static/media/weight.17d008f4.png);
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgb(255, 255, 63);
  border-radius: 0%;
}

.pathHeadSquare {
  background-color: rgb(255, 255, 63);
  background-image: url(/static/media/start.ff2b9671.svg);
}

.pathFinishedSquare,
.pathFinishedWeightSquare {
  background-color: rgb(255, 255, 63);
}

.pathFinishedWeightSquare {
  background-image: url(/static/media/weight.17d008f4.png);
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
}

.grid {
  margin: 0px auto 20px auto;
  padding: 0px;
  display: grid;
  border: 1px solid #ddd;
}

.grid:hover {
  cursor: cell;
}

.topBar {
  margin: 0px;
  padding: 0px 10px;
  box-sizing: border-box;
  background-color: rgb(38, 70, 83);
  width: 100%;
  /* height: 100%; */
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title {
  font-family: 'Mulish', sans-serif;
  font-size: 30px;
  font-weight: 1000;
  color: rgb(173, 232, 244);
  background-color: transparent;
  margin: 10px auto;
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
}

.topBarItemContainer {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin: 10px auto;
}

.algorithmForm {
  display: inline-flex;
  flex-wrap: nowrap;
}

.topBarButton,
.topBarButtonWhileAnimating {
  border: none;
  border-radius: 5px;
  height: 40px;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 300;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-left: 10px;
  margin-right: 10px;
}

.topBarButton {
  background-color: rgb(17, 138, 178);
  color: white;
}

.topBarButton:hover {
  cursor: pointer;
  background-color: rgb(6, 214, 160, 0.9);
  color: rgb(38, 70, 83);
}

.resetButton:hover {
  background-color: rgb(255, 107, 107);
}

.topBarButtonWhileAnimating,
.topBarButtonWhileAnimating:hover {
  background-color: rgb(17, 138, 178);
  color: white;
}

.topBarButton:focus,
.topBarButtonWhileAnimating:focus {
  outline: none;
}

.tutorialPageContainer {
  background-color: white;
  position: fixed;
  box-sizing: border-box;
  width: 60vw;
  height: 60vh;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border: 3px solid #457b9d;
  border-radius: 8px;
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tutorialPage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: column;
  align-content: center;
  width: 100%;
  height: 100%;
  margin: 10px;
}

.tutorialTextContainer {
  height: calc(100% - 120px);
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  overflow: auto;
  word-wrap: break-word;
  margin: 10px;
}

.tutorialText {
  text-align: center;
  color: rgb(38, 70, 83);
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 20px auto;
  padding: 20px auto;
  width: 95%;
  height: auto;
}

.skipButton,
.prevButton,
.nextButton {
  padding: 0.375rem 0.75rem;
  color: white;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin: 10px;
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.prevButton {
  background-color: #457b9d;
  padding: 0.375rem 1rem;
}

.nextButton {
  background-color: #457b9d;
  padding: 0.375rem 1rem;
}

.skipButton {
  background-color: #e63946;
  border-radius: 50%;
}

.skipButton:hover,
.prevButton:hover,
.nextButton:hover {
  cursor: pointer;
  background-color: #3b7193;
}

.skipButton:hover {
  background-color: #dc2f3c;
}

.skipButton:focus,
.prevButton:focus,
.nextButton:focus {
  outline: none;
}

.buttonContainer {
  margin: auto;
}

.skipContainer,
.directionContainer {
  margin: auto;
  display: flex;
  justify-content: center;
}

.algorithmList {
  color: rgb(38, 70, 83);
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  white-space: pre;
  margin: auto;
}

.algorithmList td {
  padding: 2px 7px;
}

.algorithmListContainer,
.siteOptionsListContainer {
  overflow-x: auto;
  overflow-y: visible;
  width: 95%;
  text-align: center;
}

.siteOptionsList {
  list-style-type: none;
  color: rgb(38, 70, 83);
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

.siteOptionsList > li {
  margin: 20px auto;
}

.mapLogo,
.algorithmLogo {
  width: 25%;
  height: 25%;
  margin: 10px auto;
}

.creativityLogo {
  width: 20%;
  height: 20%;
  margin: 10px auto;
}

@media (max-width: 800), (max-height: 1050px) {
  .tutorialText {
    font-size: 1.5rem;
  }

  .algorithmList,
  .siteOptionsList {
    font-size: 1rem;
  }

  .tutorialPageContainer {
    width: 75vw;
    height: 75vh;
  }
}

@media (max-width: 650px), (max-height: 850px) {
  .tutorialText {
    font-size: 1.3rem;
    margin: 10px;
  }

  .tutorialTextContainer {
    margin: 10px;
  }

  .algorithmList,
  .siteOptionsList {
    font-size: 1rem;
  }

  .siteOptionsList > li {
    margin: 10px;
  }

  .tutorialPageContainer {
    width: 80vw;
    height: 80vh;
  }

  .mapLogo,
  .algorithmLogo,
  .creativityLogo {
    margin: 5px auto;
  }
}

@media (max-width: 400px), (max-height: 600px) {
  .tutorialText {
    font-size: 1rem;
  }

  .algorithmList,
  .siteOptionsList {
    font-size: 0.8rem;
  }
  .tutorialPageContainer {
    width: 85vw;
    height: 85vh;
  }
}

.legendContainer {
  margin: 20px 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.legend {
  width: 25px;
  height: 25px;
  margin: 5px;
  display: flex;
}

.legendImg {
  width: 85%;
  height: 85%;
  margin: auto;
}

.legendText {
  line-height: 25px;
  height: 25px;
  margin: auto 5px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.StartLegend {
  background-color: rgb(6, 214, 160, 0.9);
}

.EndLegend {
  background-color: rgb(255, 107, 107);
}

.WallLegend {
  background-color: rgb(38, 70, 83);
}

.VisitedLegend {
  background-color: rgb(86, 207, 225);
}

.PathLegend {
  background-color: rgb(255, 255, 63);
}

body {
  margin: auto;
}

.page {
  margin: auto;
  height: 100vh;
  font-family: Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.legendsContainer {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  color: rgb(38, 70, 83);
  font-family: 'Mulish', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.footer {
  font-family: 'Mulish', sans-serif;
  font-size: 1em;
  margin-bottom: 16px;
}

.footerAnchor {
  vertical-align: middle;
}

.footerIcon {
  height: 1em; 
}

.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 200px;
  background: white;
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 1.5em;
  font: 1.2rem 'Roboto', sans-serif;
  -webkit-filter: drop-shadow(0 0 1rem #ccc);
          filter: drop-shadow(0 0 1rem #ccc);
}

.exitButton {
  position: absolute;
  top: 3px;
  left: 3px;

  margin: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  border: none;
  color: #aaa;
}

.exitButton:hover,
.helpButton:hover {
  cursor: pointer;
  background: #ddd;
}

.helpButton:hover {
  background: rgb(173, 232, 244, 0.75);
}

.exitButton:focus,
.helpButton:focus {
  outline: none;
}

.helpButton {
  position: fixed;
  bottom: 16px;
  right: 25px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #eee;
  border: 0px solid #aaa;
  background: rgb(173, 232, 244);
}

