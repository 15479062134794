@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');

body {
  margin: auto;
}

.page {
  margin: auto;
  height: 100vh;
  font-family: Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.legendsContainer {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  color: rgb(38, 70, 83);
  font-family: 'Mulish', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.footer {
  font-family: 'Mulish', sans-serif;
  font-size: 1em;
  margin-bottom: 16px;
}

.footerAnchor {
  vertical-align: middle;
}

.footerIcon {
  height: 1em; 
}
