@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500&display=swap');

.tutorialPageContainer {
  background-color: white;
  position: fixed;
  box-sizing: border-box;
  width: 60vw;
  height: 60vh;
  top: 50%;
  transform: translateY(-50%);
  border: 3px solid #457b9d;
  border-radius: 8px;
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tutorialPage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: column;
  align-content: center;
  width: 100%;
  height: 100%;
  margin: 10px;
}

.tutorialTextContainer {
  height: calc(100% - 120px);
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  overflow: auto;
  word-wrap: break-word;
  margin: 10px;
}

.tutorialText {
  text-align: center;
  color: rgb(38, 70, 83);
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 20px auto;
  padding: 20px auto;
  width: 95%;
  height: auto;
}

.skipButton,
.prevButton,
.nextButton {
  padding: 0.375rem 0.75rem;
  color: white;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin: 10px;
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  user-select: none;
}

.prevButton {
  background-color: #457b9d;
  padding: 0.375rem 1rem;
}

.nextButton {
  background-color: #457b9d;
  padding: 0.375rem 1rem;
}

.skipButton {
  background-color: #e63946;
  border-radius: 50%;
}

.skipButton:hover,
.prevButton:hover,
.nextButton:hover {
  cursor: pointer;
  background-color: #3b7193;
}

.skipButton:hover {
  background-color: #dc2f3c;
}

.skipButton:focus,
.prevButton:focus,
.nextButton:focus {
  outline: none;
}

.buttonContainer {
  margin: auto;
}

.skipContainer,
.directionContainer {
  margin: auto;
  display: flex;
  justify-content: center;
}

.algorithmList {
  color: rgb(38, 70, 83);
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  white-space: pre;
  margin: auto;
}

.algorithmList td {
  padding: 2px 7px;
}

.algorithmListContainer,
.siteOptionsListContainer {
  overflow-x: auto;
  overflow-y: visible;
  width: 95%;
  text-align: center;
}

.siteOptionsList {
  list-style-type: none;
  color: rgb(38, 70, 83);
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

.siteOptionsList > li {
  margin: 20px auto;
}

.mapLogo,
.algorithmLogo {
  width: 25%;
  height: 25%;
  margin: 10px auto;
}

.creativityLogo {
  width: 20%;
  height: 20%;
  margin: 10px auto;
}

@media (max-width: 800), (max-height: 1050px) {
  .tutorialText {
    font-size: 1.5rem;
  }

  .algorithmList,
  .siteOptionsList {
    font-size: 1rem;
  }

  .tutorialPageContainer {
    width: 75vw;
    height: 75vh;
  }
}

@media (max-width: 650px), (max-height: 850px) {
  .tutorialText {
    font-size: 1.3rem;
    margin: 10px;
  }

  .tutorialTextContainer {
    margin: 10px;
  }

  .algorithmList,
  .siteOptionsList {
    font-size: 1rem;
  }

  .siteOptionsList > li {
    margin: 10px;
  }

  .tutorialPageContainer {
    width: 80vw;
    height: 80vh;
  }

  .mapLogo,
  .algorithmLogo,
  .creativityLogo {
    margin: 5px auto;
  }
}

@media (max-width: 400px), (max-height: 600px) {
  .tutorialText {
    font-size: 1rem;
  }

  .algorithmList,
  .siteOptionsList {
    font-size: 0.8rem;
  }
  .tutorialPageContainer {
    width: 85vw;
    height: 85vh;
  }
}
