@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');

.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 200px;
  background: white;
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 1.5em;
  font: 1.2rem 'Roboto', sans-serif;
  filter: drop-shadow(0 0 1rem #ccc);
}

.exitButton {
  position: absolute;
  top: 3px;
  left: 3px;

  margin: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  border: none;
  color: #aaa;
}

.exitButton:hover,
.helpButton:hover {
  cursor: pointer;
  background: #ddd;
}

.helpButton:hover {
  background: rgb(173, 232, 244, 0.75);
}

.exitButton:focus,
.helpButton:focus {
  outline: none;
}

.helpButton {
  position: fixed;
  bottom: 16px;
  right: 25px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #eee;
  border: 0px solid #aaa;
  background: rgb(173, 232, 244);
}
